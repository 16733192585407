import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as labs from 'vuetify/labs/components';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import momentAdapter from "@date-io/moment"
import
{
    mdiViewDashboard, mdiSchool, mdiArrowBottomRightBoldBoxOutline, mdiFolderOpen, mdiAccount, mdiWalletTravel, mdiCircleMedium, mdiDraw, mdiCalendarToday, mdiCodeTags, mdiBookOpenVariant, mdiCurrencyUsd, mdiScript, mdiFlaskEmpty, mdiAccountTie, mdiCompareHorizontal, mdiAccountSupervisorCircle, mdiHuman, mdiVote, mdiCloudPrint, mdiOrderNumericAscending, mdiFileFindOutline, mdiBook, mdiWeb, mdiBank, mdiLayersTripleOutline, mdiBullhorn, mdiToolbox, mdiNfc, mdiMapMarker, mdiBell, mdiPlus, mdiDotsVertical, mdiPencil, mdiFilePdfBox, mdiLock, mdiAlertDecagram, mdiAccountSupervisor, mdiContentCopy, mdiReplay, mdiMessageAlert, mdiEmail, mdiExport, mdiExportVariant, mdiCalendar, mdiBackspace, mdiDelete, mdiCheck, mdiCloseCircleOutline, mdiClose, mdiFile, mdiHelpCircle, mdiSearchWeb, mdiMagnify, mdiSignature, mdiPublish, mdiContentDuplicate, mdiBackupRestore, mdiMenu, mdiDownload, mdiRestart, mdiArrowUDownRight, mdiForward, mdiArrowUpBold, mdiArrowDownBold, mdiRefresh, mdiCancel, mdiCheckboxMarked, mdiCheckboxBlank, mdiCheckboxBlankOutline, mdiFileImport, mdiCallMerge, mdiAlertCircle, mdiCheckCircle, mdiRadioboxBlank, mdiScriptTextOutline, mdiUndo, mdiEye, mdiAlert, mdiCardSearch, mdiCircle, mdiEarHearing, mdiOfficeBuilding, mdiArchiveArrowUp, mdiArchiveArrowDown,
    mdiHome,
    mdiLink,
    mdiLinkVariant,
    mdiPaperclip,
    mdiMovie,
    mdiRadio,
    mdiMenuRight,
    mdiMenuDown,
    mdiMenuUp,
    mdiCamera,
    mdiChairRolling,
    mdiFileDocumentArrowRightOutline,
    mdiImage,
    mdiNumeric0,
    mdiNumeric0Box,
    mdiNumeric1,
    mdiNumeric1Box,
    mdiNumeric2,
    mdiNumeric2Box,
    mdiNumeric3,
    mdiNumeric3Box,
    mdiNumeric4,
    mdiNumeric4Box,
    mdiNumeric5,
    mdiNumeric5Box,
    mdiNumeric6,
    mdiNumeric6Box,
    mdiNumeric7,
    mdiNumeric7Box,
    mdiNumeric8,
    mdiNumeric8Box,
    mdiNumeric9,
    mdiNumeric9Box,
    mdiNumeric10,
    mdiNumeric10Box,
    mdiSignYield,
    mdiChartBox,
    mdiMarker,
    mdiNoteEdit,
    mdiClipboardText,
    mdiNewspaper,
    mdiFileDocument,
    mdiCalendarBlank,
    mdiCalendarAccount,
    mdiText,
    mdiRuler,
    mdiBellAlert,
    mdiStar,
    mdiStarOutline,
    mdiPrinter,
    mdiGroup,
    mdiThumbUp,
    mdiThumbDown,
    mdiCloudDownload,
    mdiChevronLeft,
    mdiChevronRight,
    mdiViewAgenda,
    mdiDeleteForever,
    mdiChevronUp,
    mdiChevronDown,
    mdiAccountPlus,
    mdiChat,
    mdiInformation,
    mdiAccountBox,
    mdiEyeOff,
    mdiTransitTransfer,
    mdiFormatListChecks,
    mdiHandshake,
    mdiCar,
    mdiCog,
    mdiLogout,
    mdiFastForward,
    mdiEmailMarkAsUnread,
    mdiMessageText,
    mdiFormatLineStyle,
    mdiAccountMultiple
} from '@mdi/js';

const lightDevelopment = {
    dark: false,
    colors: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#185200",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
    }
};

const darkDevelopment = {
    dark: true,
    colors: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#185200",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
    }
};

const lightTest = {
    dark: false,
    colors: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#a80000",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
    }
};

const darkTest = {
    dark: true,
    colors: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#a80000",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
    }
};

const lightTraining = {
    dark: false,
    colors: {
        primary: "#1976D2",
        secondary: "#424242",
        warning: "#FFC107",
        accent: "#8b94b0",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
    }
};

const darkTraining = {
    dark: true,
    colors: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#8b94b0",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
    }
};

const lightProduction = {
    dark: false,
    colors: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#949494",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
    }
};

const darkProduction = {
    dark: true,
    colors: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#949494",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
    }
};

export default createVuetify(
    {
        defaults: {
            VCardActions: {
                VBtn: { variant: 'elevated', color: 'primary' },
            },
            VToolbar: {
                VBtn: { variant: 'elevated', color: 'primary' },
            },
            VTooltip: {
                eager: false
            },
            VAutocomplete: {
                variant: 'outlined',
                density: 'compact',
                hideDetails: 'auto'
            },
            VCombobox: {
                variant: 'outlined',
                density: 'compact',
                hideDetails: 'auto'
            },
            VTextField: {
                variant: 'outlined',
                density: 'compact',
                hideDetails: 'auto'
            },
            VSelect: {
                variant: 'outlined',
                density: 'compact',
                hideDetails: 'auto'
            },
            VTextarea: {
                variant: 'outlined',
                density: 'compact',
                hideDetails: 'auto'
            },
            VFileInput: {
                variant: 'outlined',
                density: 'compact',
                hideDetails: 'auto'
            },
            VSwitch: {
                color: 'primary',
                inset: true,
                flat: true
            },
            VChip: {
                closeIcon: '$close'
            }
        },
        icons: {
            defaultSet: 'mdi',
            aliases: {
                ...aliases,
                'view-dashboard': mdiViewDashboard,
                'school': mdiSchool,
                'folder_open': mdiFolderOpen,
                'person': mdiAccount,
                'account': mdiAccount,
                'accountPlus': mdiAccountPlus,
                'wallet-travel': mdiWalletTravel,
                'circle-medium': mdiCircleMedium,
                'fa-signature': mdiDraw,
                'draw': mdiDraw,
                'today': mdiCalendarToday,
                'calendar': mdiCalendarBlank,
                'calendar-today': mdiCalendarToday,
                'event': mdiCalendarBlank,
                'code': mdiCodeTags,
                'code-tags': mdiCodeTags,
                'menu-book': mdiBookOpenVariant,
                'book-open-variant': mdiBookOpenVariant,
                'currency-usd': mdiCurrencyUsd,
                'fa-scroll': mdiScript,
                'script': mdiScript,
                'science': mdiFlaskEmpty,
                'flask-empty': mdiFlaskEmpty,
                'account-tie': mdiAccountTie,
                'compare-horizontal': mdiCompareHorizontal,
                'account-supervisor-circle': mdiAccountSupervisorCircle,
                'human': mdiHuman,
                'vote': mdiVote,
                'cloud-print': mdiCloudPrint,
                'print': mdiPrinter,
                'order-numeric-ascending': mdiOrderNumericAscending,
                'file-find-outline': mdiFileFindOutline,
                'mdiOrderNumericAscending': mdiOrderNumericAscending,
                'book': mdiBook,
                'web': mdiWeb,
                'bank': mdiBank,
                'layers-triple-outline': mdiLayersTripleOutline,
                'bullhorn': mdiBullhorn,
                'toolbox': mdiToolbox,
                'nfc': mdiNfc,
                'map-marker': mdiMapMarker,
                'bell': mdiBell,
                'plus': mdiPlus,
                'dotsVertical': mdiDotsVertical,
                'arrowBottomRightBoldBoxOutline': mdiArrowBottomRightBoldBoxOutline,
                'pencil': mdiPencil,
                'edit': mdiPencil,
                'filePdfBox': mdiFilePdfBox,
                'lock': mdiLock,
                'alert-decagram': mdiAlertDecagram,
                'account-supervisor': mdiAccountSupervisor,
                'content-copy': mdiContentCopy,
                'replay': mdiReplay,
                'message-alert': mdiMessageAlert,
                'email': mdiEmail,
                'export-variant': mdiExportVariant,
                'export': mdiExport,
                'backspace': mdiBackspace,
                'delete': mdiDelete,
                'deleteForever': mdiDeleteForever,
                'check': mdiCheck,
                'closeCircleOutline': mdiCloseCircleOutline,
                'close': mdiClose,
                'file': mdiFile,
                'help': mdiHelpCircle,
                'search': mdiMagnify,
                'signature': mdiSignature,
                'publish': mdiPublish,
                'content-duplicate': mdiContentDuplicate,
                'backup-restore': mdiBackupRestore,
                'menu': mdiMenu,
                "download": mdiDownload,
                'restart': mdiRestart,
                'arrowUDownRight': mdiArrowUDownRight,
                'forward': mdiForward,
                'arrowUpBold': mdiArrowUpBold,
                'arrowDownBold': mdiArrowDownBold,
                'refresh': mdiRefresh,
                'cancel': mdiCancel,
                'checkboxMarked': mdiCheckboxMarked,
                'checkboxBlank': mdiCheckboxBlankOutline,
                'file-import': mdiFileImport,
                'call-merge': mdiCallMerge,
                'alert-circle': mdiAlertCircle,
                'check-circle': mdiCheckCircle,
                'radiobox-blank': mdiRadioboxBlank,
                'undo': mdiUndo,
                'scriptTextOutline': mdiScriptTextOutline,
                'visibility': mdiEye,
                'visibility-off': mdiEyeOff,
                'circle': mdiCircle,
                'ear-hearing': mdiEarHearing,
                'alert': mdiAlert,
                'card-search': mdiCardSearch,
                'officeBuilding': mdiOfficeBuilding,
                'archiveArrowUp': mdiArchiveArrowUp,
                'archiveArrowDown': mdiArchiveArrowDown,
                'home': mdiHome,
                'link': mdiLinkVariant,
                'paperclip': mdiPaperclip,
                'movie': mdiMovie,
                'radio': mdiRadio,
                'menu-right': mdiMenuRight,
                'menu-down': mdiMenuDown,
                'menu-up': mdiMenuUp,
                'camera': mdiCamera,
                'assignment': mdiClipboardText,
                'description': mdiFileDocument,
                'newspaper': mdiNewspaper,
                'notes': mdiText,
                'ruler': mdiRuler,
                'notification_important': mdiBellAlert,
                'group': mdiGroup,
                'cloud-download': mdiCloudDownload,
                'settings': mdiCog,
                'logout': mdiLogout,
                'line_style': mdiFormatLineStyle,
                mdiChairRolling,
                mdiFileDocumentArrowRightOutline,
                mdiImage,
                mdiNumeric0,
                mdiNumeric0Box,
                mdiNumeric1,
                mdiNumeric1Box,
                mdiNumeric2,
                mdiNumeric2Box,
                mdiNumeric3,
                mdiNumeric3Box,
                mdiNumeric4,
                mdiNumeric4Box,
                mdiNumeric5,
                mdiNumeric5Box,
                mdiNumeric6,
                mdiNumeric6Box,
                mdiNumeric7,
                mdiNumeric7Box,
                mdiNumeric8,
                mdiNumeric8Box,
                mdiNumeric9,
                mdiNumeric9Box,
                mdiNumeric10,
                mdiNumeric10Box,
                mdiSignYield,
                mdiChartBox,
                mdiMarker,
                mdiNoteEdit,
                mdiCalendarAccount,
                mdiStar,
                mdiStarOutline,
                mdiThumbUp,
                mdiThumbDown,
                mdiChevronLeft,
                mdiChevronRight,
                mdiChevronUp,
                mdiChevronDown,
                mdiViewAgenda,
                mdiChat,
                mdiInformation,
                mdiAccountBox,
                mdiTransitTransfer,
                mdiFormatListChecks,
                mdiCar,
                mdiHandshake,
                mdiFastForward,
                mdiEmailMarkAsUnread,
                mdiMessageText,
                mdiAccountMultiple
            },
            sets: {
                mdi,
            },
        },
        theme: {
            defaultTheme: 'light',
            themes: {
                lightDevelopment,
                darkDevelopment,
                lightTest,
                darkTest,
                lightTraining,
                darkTraining,
                lightProduction,
                darkProduction
            }
        },
       
        date: {
       
            adapter: momentAdapter,
        },
        components: {
            ...labs
        }
    });