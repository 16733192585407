
import { createRouter, createWebHistory } from 'vue-router';

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '',
            component: () => import("@/Index.vue"),
            children: [
                {
                    path: '',
                    component: () => import("@/MyLRC.vue"),
                    children: [
                        {
                            path: '',
                            component: () => import("@/Login.vue"),
                        },
                        {
                            path: '/Unsubscribe.html',
                            params: true,
                            component: () => import("@/Unsubscribe/Unsubscribe.vue")
                        },
                        {
                            path: '/forgotUsernamePassword.html',
                            params: true,
                            component: () => import("@/ForgetPassword/ForgetPassword.vue")
                        },
                        {
                            path: '/Verify.html',
                            params: true,
                            component: () => import("@/Verify/Verify.vue")
                        },
                        {
                            path: '/PublicAPI.html',
                            params: true,
                            component: () => import("@/PublicAPI/PublicAPI.vue")
                        },
                        {
                            path: '/DraftLegislation.html',
                            params: true,
                            component: () => import("@/DraftLegislation/DraftLegislation.vue")
                        },
                        {
                            path: '/SDPB/Audio.html',
                            params: true,
                            component: () => import("@/SDPB/Audio.vue")
                        },
                    ]

                },
                {

                    path: '/Main',
                    component: () => import("@/App.vue"),
                    children: [

                        {
                            path: 'Dashboard',
                            redirect: '/main/Requests'
                        },
                        {
                            path: 'Calendar/:BillListId?',
                            props: true,
                            component: () => import("@/Committee/Calendar.vue")
                        },
                        {
                            path: 'LettersOfIntentReports',
                            component: () => import("@/AgencyStuff/LettersOfIntentReports.vue"),
                        },
                        {
                            path: "Resources",
                            name: "Resources",
                            component: () => import("@/Resources/Resources.vue"),
                        },
                        {
                            path: "Documents",
                            name: "Documents",
                            props: { ShowPublicDocuments: true, ShowFavoriteDocuments: true, ShowRecentDocuments: true, ShowCommitteeDocuments: true },
                            component: () => import("@/Documents/TabDocuments.vue"),
                        },
                        {
                            path: 'Requests',
                            props: true,
                            component: () => import("@/Requests/Requests.vue"),
                        },
                        {
                            path: 'Document/:DocumentId?/:Guid?',
                            props: true,
                            component: () => import("@/Requests/Requests.vue"),
                        },
                        {
                            path: 'LegislatorUpdate',
                            component: () => import("@/Resources/LegislatorUpdate.vue"),
                        },
                        {
                            path: 'Notes',
                            component: () => import("@/Notes/Notes.vue"),
                        },
                        {
                            path: 'Rules',
                            component: () => import("@/Rules/Rules.vue"),
                        },
                        {
                            path: "BillLists",
                            name: "BillLists",
                            component: () => import("@/BillLists/BillLists.vue"),
                        },
                        {
                            path: "BillList",
                            name: "BillList",
                            component: () => import("@/BillLists/BillList.vue"),
                        },
                        {
                            path: "AddBill",
                            name: "AddBill",
                            component: () => import("@/BillLists/AddBill.vue"),
                        },
                        {
                            path: "AddCriteria",
                            name: "AddCriteria",
                            component: () => import("@/BillLists/AddCriteria.vue"),
                        },
                        {
                            path: 'Statutes',
                            component: () => import("@/Statutes/Statutes.vue"),
                        },
                        {
                            path: 'SessionCommittees',
                            name: "SessionCommittees",
                            component: () => import("@/SessionCommittees/SessionCommittees.vue"),
                        },
                        {
                            path: 'InterimCommittees',
                            name: 'InterimCommittees',
                            component: () => import("@/InterimCommittees/InterimCommittees.vue"),
                        },
                        {
                            path: 'ArchivedSessions',
                            component: () => import("@/ArchivedSessions/ArchivedSessions.vue"),
                            name: 'ArchivedSessions',

                        },
                        {
                            path: 'ArchivedSessions/ArchivedRequests',
                            props: true,
                            component: () => import("@/ArchivedSessions/ArchivedRequests.vue"),
                        },
                        {
                            path: 'Notifications',
                            component: () => import("@/Notifications/Notifications.vue"),
                            name: 'Notifications'
                        },
                        {
                            path: 'Tasks',
                            component: () => import("@/Tasks/Tasks.vue"),
                            name: 'Tasks'
                        },
                        {
                            path: 'Committee/BillLogs',
                            name: 'BillLogs',
                            component: () => import("@/Committee/BillLogs.vue"),
                        },
                        {
                            path: 'Sponsorship',
                            name: 'Sponsorship',
                            component: () => import("@/Sponsorship/Sponsorship.vue")
                        },
                        {
                            path: 'Survey/Intern',
                            component: () => import("@/Survey/Intern.vue"),
                        },
                        {
                            path: 'Survey/Legislator',
                            component: () => import("@/Survey/Legislator.vue"),
                        },
                        {
                            path: 'Survey/LRC',
                            component: () => import("@/Survey/LRC.vue"),
                        },
                        {
                            path: 'Survey/Surveys',
                            component: () => import("@/Survey/Surveys.vue"),
                        },
                        {
                            path: 'Survey/General/:surveyId?',
                            props: true,
                            component: () => import("@/Survey/General.vue"),
                        },
                        {
                            path: 'TravelRequest',
                            component: () => import("@/TravelRequest/TravelRequest.vue"),
                        },
                        {
                            path: 'Assignments/Index',
                            component: () => import("@/Assignments/Index.vue"),
                        },
                        {
                            path: 'Assignments/Bills',
                            component: () => import("@/Assignments/Bills.vue"),
                        },
                        {
                            path: 'Assignments/Members',
                            component: () => import("@/Assignments/MemberAssignment.vue"),
                        },
                        {
                            path: 'InterimStudies',
                            redirect: () => '/InterimStudies/InterimStudies',
                        },
                        {
                            path: 'InterimStudies/InterimStudies',
                            component: () => import("@/InterimStudies/InterimStudies.vue"),
                        },
                        {
                            path: 'InterimStudies/Rank',
                            component: () => import("@/InterimStudies/Rank.vue"),
                        },
                        {
                            path: 'InterimStudies/Serve',
                            component: () => import("@/InterimStudies/Serve.vue"),
                        },
                        {
                            path: 'Scenarios/:fiscalYear',
                            component: () => import("@/Legislator/Scenarios.vue"),
                            name: 'Scenarios',
                            props: true
                        },
                        {
                            path: 'ScenariosViewer/:selectedYear/:selectedAgency',
                            component: () => import("@/Legislator/ScenariosViewer.vue"),
                            name: 'ScenariosViewer',
                            props: true
                        },
                        {
                            path: 'Settings',
                            component: () => import("@/Settings/Settings.vue"),
                        },
                        {
                            path: 'Dashboard',
                            redirect: '/Requests'
                        },
                        {
                            path: 'Students/Intern/View',
                            component: () => import("@/Students/View.vue"),
                        },
                        {
                            name: 'InternApplication',
                            path: 'Students/Intern/Application/:InternId?',
                            props: (x) => {
                                return {
                                    Title: 'Intern Application',
                                    InternId: x.params.InternId
                                };
                            },
                            component: () => import("@/Students/Application.vue"),
                        },
                        {
                            name: 'PageApplication',
                            path: 'Students/Page/Application/:PersonId?',
                            props: {
                                Title: 'Page Application',
                            },
                            component: () => import("@/Students/Application.vue"),
                        },
                        {
                            path: 'CommitteeLive',
                            component: () => import("@/CommitteeLive/CommitteeLive.vue"),
                        },
                        {
                            path: '/Live/:DocumentId',
                            props: true,
                            component: () => import('@/CommitteeLive/Live.vue')
                        },
                    ]
                }                
            ]
        },
        {
            path: '/',
            redirect: ''
        },

       
    ]
})