
function maskZip(value)
{
    value = (value || '').replace(/\-/g, '');

    if (value.length > 5)
    {
        return value.substring(0, 5) + "-" + value.substring(5)
    }
    else
    {
        return value
    }

}
export default {
    bind: function (el, binding, vnode)
    {
        let input = el instanceof HTMLInputElement
            ? el
            : el.querySelector('input') || el
        input.addEventListener('blur', () =>
        {
            input.value = maskZip(input.value);
            input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
        })
    }
}