<template>
    <router-view v-slot="{Component}">
        <transition name="fade" mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>
</template>
<script>
    import { useTheme } from 'vuetify';
    export default {
        setup() {
            return {
                theme: useTheme(),
            };
        },
       
        mounted: function () {
         
        },
        watch: {
          
        },
        computed:
        {
        }
    }
</script>