import phonenumber from '@reallyuseful/phonenumber'
//const phonenumber = require('@reallyuseful/phonenumber');
function maskPhone(value) {
    if (phonenumber.valid(value)) {
        return phonenumber.friendly(value)
    }
    else {
        return value
    }
    
}
export default {
    bind: function (el, binding, vnode) {
        let input = el instanceof HTMLInputElement
            ? el
            : el.querySelector('input') || el
        input.addEventListener('blur', () => {
            input.value = maskPhone(input.value);
            input.dispatchEvent(new Event('input', { bubbles:true, cancelable:true}));
        })
    }
}
